// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-de-investidor-para-investidor-index-tsx": () => import("./../../../src/pages/de-investidor-para-investidor/index.tsx" /* webpackChunkName: "component---src-pages-de-investidor-para-investidor-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-quem-somos-index-tsx": () => import("./../../../src/pages/quem-somos/index.tsx" /* webpackChunkName: "component---src-pages-quem-somos-index-tsx" */),
  "component---src-pages-search-index-tsx": () => import("./../../../src/pages/search/index.tsx" /* webpackChunkName: "component---src-pages-search-index-tsx" */),
  "component---src-templates-article-index-tsx": () => import("./../../../src/templates/article/index.tsx" /* webpackChunkName: "component---src-templates-article-index-tsx" */),
  "component---src-templates-category-index-tsx": () => import("./../../../src/templates/category/index.tsx" /* webpackChunkName: "component---src-templates-category-index-tsx" */),
  "component---src-templates-columnists-index-tsx": () => import("./../../../src/templates/columnists/index.tsx" /* webpackChunkName: "component---src-templates-columnists-index-tsx" */),
  "component---src-templates-single-video-index-tsx": () => import("./../../../src/templates/single-video/index.tsx" /* webpackChunkName: "component---src-templates-single-video-index-tsx" */),
  "component---src-templates-stocks-template-index-tsx": () => import("./../../../src/templates/stocks-template/index.tsx" /* webpackChunkName: "component---src-templates-stocks-template-index-tsx" */),
  "component---src-templates-subcategory-index-tsx": () => import("./../../../src/templates/subcategory/index.tsx" /* webpackChunkName: "component---src-templates-subcategory-index-tsx" */)
}

